import { Injectable, signal } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class BreakpointService {
  isSmallViewport = signal(false);
  isMediumViewport = signal(false);
  isLargeViewport = signal(false);
  constructor() { }
}
